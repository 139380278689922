.list {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1em 3em 0 3em;
}
.data {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.data h1 {
  margin: 0;
}
.data p {
  font-size: 1.1em;
  font-family: "Trade Gothic LT Std Light";
}
.descripcion {
  width: 90%;
  margin-top: 1em;
}
.vinoImg {
  width: 40%;
  display: flex;
  justify-content: center;
}
.vinoImg img {
  width: 12vw;
}
.boton {
  width: 50%;
  padding-top: 1em;
  margin-bottom: 3em;
  margin: 0 auto;
}
.boton button {
  font-family: "Trade Gothic LT Std Light";
  background-color: black;
  color: white;
  border-radius: 8px;
  width: 14rem;
  height: 2rem;
}

@media screen and (max-width: 768px) {
  .data {
    align-items: center;
  }
  .data h1 {
    margin-top: 2em;
    font-size: 1.5em;
    width: 90%;
    text-align: center;
  }
  .data p {
    font-size: 1.2em;
  }
  .list {
    flex-direction: column;
  }
  .descripcion {
    width: 80vw;
  }
  .boton {
    padding: 0;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .boton button {
    font-size: 1em;
  }
  .vinoImg {
    width: 80vw;
  }
  .vinoImg img {
    width: 60%;
  }
  .vinoImg img {
    padding: 0;
  }
}
