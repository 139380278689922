@import url("https://db.onlinewebfonts.com/c/3f668921460e0feff1eb01deb0dd4c68?family=Trade+Gothic+LT+Std");
@import url("https://db.onlinewebfonts.com/c/48598fb3b511f4c4ec8058be4159d72b?family=Trade+Gothic+LT+Std+Light");
body {
  font-family: "Trade Gothic LT Std Light";
  overflow-x: hidden;
  margin: 0;
}
a {
  color: black;
  text-decoration: none;
}