.itemCount{
    width: min-content;
    height: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5em 0;
    background-color: white;
    border-radius: 5px;
    border: 1px solid black;
}
.restButton, .addButton{
    background-color: transparent;
    width: 1.5em;
    height: 1.5em;
    border: none;
    font-size: 1em;
}
.restButton:disabled{
    color: gray;
}
.itemCount, .itemCountButton button{
    font-family: "Trade Gothic LT Std Light";
}
.number{
    color: black;
    background-color: transparent;
    text-align: center;
    width: 2em;
    height: 1.5em;
    font-size: 1em;
    padding-top: 0.3em;
}
.itemCountButton{
    width: 50%;
}
.itemCountButton button{
    background-color: black;
    color: white;
    border-radius: 8px;
    width: 14rem;
    height: 2rem;
}

@media screen and (max-width: 768px) {
    .itemCountButton button{
        width: 100%;
        font-size: 1em;
    }
}