.footer {
  border-top: solid 1px rgba(0, 0, 0, 0.329);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 2em;
}
.footerLogo {
  margin-top: 1em;
  width: 30%;
}
.footer p {
  margin: 0.5em 0 0.5em 0.5em;
}
.limbo {
  padding-left: 0.6em;
}
.info a {
  display: flex;
  align-items: center;
  margin-left: 1em;
}
.info a img {
  width: 3%;
}
.footerLocation {
  width: 1%;
}
.redes img {
  width: 2rem;
}
.copyright {
  text-align: center;
  font-family: "Trade Gothic LT Std Light";
}

@media screen and (max-width: 1346px) {
  .footer{
    flex-direction: column;
    justify-content: left;
  }
  .footer div img {
    width: 70%;
  }
  .info p {
    font-size: 1.2em;
    margin-left: 1em;
  }
  .limbo {
    padding-left: 0;
  }
  .info a img {
    width: 1.5em;
  }
  .redes {
    margin-right: 8em;
  }
  .redes a img {
    margin: 1em auto;
    width: 2em;
  }
}