.container-home {
  padding: 0 10vw;
  margin: auto;
  margin-bottom: 6rem;
}

.home-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  
}

.vinosTitle{
  text-align: center;
}

.nuestra-cosecha {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin-left: 8em;
}

.nuestra-cosecha div {
  margin: 1em 0;
}

.nuestra-cosecha div p {
  margin: 0;
  text-align: center;
}

.nuestra-cosecha div img{
  width: 14vw;
}

.botonHome {
  margin: auto;
}

.botonHome button {
  display: block;
  font-family: "Trade Gothic LT Std Light";
  background-color: black;
  color: white;
  border-radius: 8px;
  width: 14rem;
  height: 2rem;
}

button {
  font-size: 1.1em;
}

button:hover {
  transform: scale(1.1, 1.1);
}

@media (max-width: 768px) {
  .home-bottom div h1 {
    font-size: 2em;
    text-align: center;
  }
  .nuestra-cosecha {
    margin: 0;
  }
  .nuestra-cosecha a div {
    margin: 1em 0 1em 3em;
  }
  .nuestra-cosecha a div p {
    font-size: 1.3em;
    text-align: center;
  }
  .nuestra-cosecha a div img {
    width: 100%;
    margin-left: 0.3em;
  }
  .botonHome button {
    font-size: 1em;
    margin: auto;
  }
}