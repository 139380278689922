.cart{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cartQuantity{
    background-color: rgb(0, 0, 0);
    color: white;
    height: 1.2em;
    width: 1.2em;
    border-radius: 50%;
    margin-left: -0.5em;
    margin-top: -1.5em;
    font-size: 0.7em;
    outline: 1px solid rgb(0, 0, 0);
    text-align: center;
    font-family: "Trade Gothic LT Std Light";
}

@media screen and (max-width: 768px) {
    .cartQuantity {
        font-size: 0.9em;
    }
}