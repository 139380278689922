.card{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    font-family: "Trade Gothic LT Std Light";
}
.card h2{
    width: max-content;
    padding-right: 1em;
}
.cardImg{
    width: 7%;
}
.cardImg img{
    padding-left: 3em;
    width: 15%;
}
.trash{
    width: 2%;
    padding-left: 1em;
}
.trash:hover {
    transform: scale(1.2, 1.2);
}

@media screen and (max-width: 768px) {
    .trash{
        width: 10%;
        padding-right: 1em;
    }
    .card h2, .card p {
        font-size: 1.2em;
    }
    .card h2 {
        width: 60%;
        padding-left: 1em;
    }
    .card{
        justify-content: left;
        margin-bottom: 1em;
    }
    .cardImg {
        display: none;
    }
    .card{
        width: 100vw;
    }
}