.contenedor-quienes-somos {
  margin: 2em 0 0 0;
}

.quienes-somos-top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contenido {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.contenido p {
  width: 100%;
  justify-content: center;
  text-align: left;
  font-size: 1.4em;
}

.contenedor-img {
  width: auto;
  min-height: max-content;
}

.img-osvaldoMaria {
  border-radius: 12px;
  height: 80vh;
}

.quienes-somos-buttom {
  padding: 5vh 9vw;
}

.etiquetas-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.etiquetas-text div p {
  text-align: left;
  font-size: 1.4em;
}

.etiquetas-text div {
  width: 100%;
  text-align: center;
}

.etiquetasImg {
  margin: 3em 0 3em 2em;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: auto;
}

.etiquetasImg div {
  margin: 0 10px;
}

.etiquetasImg div div img {
  box-shadow: 5px 5px 5px grey;
  width: 16vw;
  height: 20em;
}

.etiquetasBotellas {
  margin: 4rem 0;
  text-align: center;
}

.etiquetasBotellas div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.etiquetasBotellas div img {
  box-shadow: 5px 5px 5px grey;
  width: 16vw;
  height: 25em;
}

.galeriaQuienesSomos {
  display: flex;
  justify-content: space-between;
  margin: 2em 6em 2em 0;
}

.galeriaQuienesSomos div p {
  font-size: 1em;
  padding-left: 1.6em;
}

.imagen-quienesSomos {
  flex: 1;
  max-width: 90%;
  margin: 5px;
  border-radius: 8px;
  min-height: 50vh;
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.imagen-quienesSomos:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .contenedor-quienes-somos {
    padding-left: 1em;
  }
  .contenedor-img {
    width: 90%;
    margin: 1em auto 1em 1em;
  }
  .img-osvaldoMaria{
    max-height: 70vh;
  }
  .contenido {
    width: 90vw;
    font-size: xx-small;
    align-items: center;
    margin: auto;
  }
  .contenido h1, .etiquetas-text div h2 {
    font-size: 3em;
  }
  .contenido p, .etiquetas-text div p, .etiquetasImg h3, .etiquetasImg p, .etiquetasBotellas p, .galeriaQuienesSomos p {
    font-size: 2em;
  }
  .quienes-somos-top, .etiquetasImg {
  flex-direction: column;
  }
  .quienes-somos-buttom{
    width: 90vw;
    font-size: xx-small;
    padding: 0;
    margin: auto;
  }
  .img-etiquetas {
    display: none;
  }
  .etiquetasImg div{
    margin: 1em 1em 1em 0;
  }
  .etiquetas-text{
    align-items: center;
  }
  .etiquetasBotellas, .etiquetasBotellas div, .contenedor-img img {
    width: 100%;
  }
  .etiquetasImg div div img {
    width: 90%;
    height: 100%;
  }
  .etiquetasBotellas div {
    flex-direction: column;
  }
  .etiquetasBotellas div img {
    margin: 1em auto;
    width: 90%;
    height: 100%;
  }
  .galeriaQuienesSomos{
    margin: 2em 0;
    flex-direction: column;
  }
  .galeriaQuienesSomos div {
    width: 98%;
  }
  .galeriaQuienesSomos div p {
    text-align: center;
    margin-right: 1em;
  }
  .imagen-quienesSomos {
    max-width: 100%;
  }
}
