.titles {
  background-size: contain;
  background-position: center center;
  text-align: center;
  font-family: "Trade Gothic LT Std Light";
}

.titles h1 {
  margin: 4rem;
}

.titles p {
  font-weight: bold;
  font-size: 2em;
}

.text-up {
  display: flex;
  justify-content: space-evenly;
}

.text-up p {
  font-size: 1.4em;
  font-family: "Trade Gothic LT Std Light";
  width: 40vw;
  text-align: justify;
}

.text-down {
  padding: 2rem 6vw;
  background-image: url("../../../assets/fondo-sierras.png");
  background-position: center;
  background-size: contain;
}

.text-down h3 {
  font-size: 1.4em;
}

.text-down p {
  font-size: 1.4em;
  font-family: "Trade Gothic LT Std Light";
}

.gallery {
  padding: 2rem 6vw;
}

.gallery h1 {
  margin-left: 0.7em;
}

.viñedo {
  display: flex;
  justify-content: space-around;
}

.finca {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.finca div {
  margin-bottom: 3rem;
  overflow: hidden;
}

.finca p {
  margin: 0.5em 1.5vw;
}

.cosecha {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.turistas {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.f {
  width: 25vw;
  height: 60vh;
  object-fit: cover;
  display: flex;
  justify-content: center;
  margin: 0 1.5vw;
  border-radius: 6px;
  display: block;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  text-align: center;
}
.modal-content img {
  height: 100vh;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.finca div img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .titles {
    width: 80%;
    margin: 0 auto;
  }
  .titles h1 {
    margin: 0;
    font-size: 1.5em;
  }
  .titles p {
    font-size: 1.5em;
  }
  .text-up {
    flex-direction: column;
    align-items: center;
  }
  .text-up p {
    text-align: left;
    width: 80vw;
    font-size: 1.2em;
  }
  .text-down {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .text-down h3 {
    font-size: small;
    margin: 20px 0;
    width: 80vw;
  }
  .text-down p {
    font-size: 1.2em;
    width: 80%;
  }
  .gallery h1 {
    font-size: 1.5em;
    margin-left: 0.7em;
  }
  .gallery .finca {
    display: flex;
    flex-direction: column;
  }
  .f {
    width: 80vw;
    height: 100%;
  }
  .finca div {
    margin: 1em auto;
  }
  .finca div p {
    font-size: 1.2em;
  }
  .finca div img:hover {
    transform: none;
  }
  .modal-content img {
    width: 100vw;
    height: 100vh;
  }
}
