.container-vyb {
  padding: 2vh 10vw 10vh 10vw;
}

.container-vyb h2, .bodega h2, .cava h2 {
  font-size: 2em;
}

.text {
  margin: 2em 0;
  font-size: 1.4em;
  text-align: justify;
}

.portada-viñedo, .cava-img, .viñedo-gallery {
  display: flex;
}

.portada-viñedo div, .bodega-img div {
  margin-right: 12px;
}

.imgProyecto {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.imgProyecto:hover, .imgViñedo:hover {
  transform: scale(1.05);
}

.bodega-img {
  display: flex;
  justify-content: center;
}

.cava-img div {
  padding-right: 12px;
}

.cava-img div img {
  width: 19vw;
  height: 85%;
}

.viñedo-gallery div {
  padding-right: 12px;
  padding-bottom: 2em;
}

.viñedo-gallery div video {
  width: 19vw;
  height: 50vh;
  border-radius: 8px;
}

.embalaje div video {
  width: 19vw;
  height: 80%;
  border-radius: 8px;
}

.viñedo-gallery div img {
  width: 19vw;
  border-radius: 8px;
}

.imgViñedo {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.embalaje {
  display: flex;
  justify-content: space-between;
}

.embalaje div img, .embalaje div video {
  width: 22vw;
  height: 80%;
  border-radius: 8px;
}

.modal-content video{
  height: 91vh;
}

@media screen and (max-width: 768px) {
  .container-vyb div h2, .text p {
    font-size: 2em;
  }
  .text {
    font-size: xx-small;
  }
  .text p {
    margin: 1em 0 2em 0;
  }
  .portada-viñedo {
    flex-direction: column;
    align-items: center;
  }
  .portada-viñedo div {
    margin: 0;
  }
  .portada-viñedo div, .viñedo-gallery div, .embalaje div, .text {
    margin: 1em auto;
  }
  .text, .portada-viñedo div p, .viñedo-gallery div p, .embalaje div p {
    font-size: 1.2em;
  }
  .imgProyecto, .viñedo-gallery div img, .cava-img div img, .embalaje div img {
    width: 80vw;
    height: 50vh;
    object-fit: cover;
  }
  .viñedo-gallery, .bodega-img, .cava-img, .embalaje {
    flex-direction: column;
  }
  .viñedo-gallery div {
    padding: 0;
  }
  .bodega-img div p {
    padding-bottom: 1em;
  }
  .bodega-img div img {
    width: 110%;
    height: 80%;
  }
  .viñedo-gallery div video, .embalaje div video {
    width: 80vw;
    border-radius: 8px;
  }
  .embalaje div video {
    height: 80%;
  }
  .modal-content {
    max-width: 80%;
    max-height: 70vh;
  }
  .img-modal {
    width: 100%;
    height: auto;
  }
}
