.emptyCart {
  text-align: center;
  margin-bottom: 3em;
}
.cartTitle {
  margin-bottom: 1.5em;
  text-align: center;
}
.buttonContainer {
  width: 40%;
  display: flex;
  margin: 0 auto 2em auto;
}

@media screen and (max-width: 768px) {
  .cartTitle {
    font-size: 2em;
  }
  .buttonContainer{
    width: 100vw;
    margin: 0;
    margin-bottom: 2em;
    flex-direction: column;
  }
  .buttonContainer .boton button{
    font-size: 1em;
  }
  .emptyCart{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .emptyCart h2 {
    font-size: 2em;
    width: 70%;
  }
}
